@import "variables";

.spinnerWrapper {
  position: absolute;
  top: 50vh;
  left: calc(50% - 44px);
}

p {
  margin: 0;
}

.listWrapperItem {
  cursor: default;
}
