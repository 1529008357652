@import "variables";
@import "../../shared/styles/ListItemStyles.scss";

.wrapper {
  margin-bottom: $spacing-4;
}

.heading {
  padding-bottom: $spacing-2;
}
