import SelectOption from "../../types";

export const rideUtilizationOptions: Array<SelectOption> = [
  { value: 80, label: "80%" },
  { value: 70, label: "70%" },
  { value: 60, label: "60%" },
  { value: 50, label: "50%" },
];

export const getRideUtilization = (utilization: number) => {
  switch (utilization) {
    case 80:
      return rideUtilizationOptions[0];
    case 70:
      return rideUtilizationOptions[1];
    case 60:
      return rideUtilizationOptions[2];
    case 50:
      return rideUtilizationOptions[3];
    default:
      return rideUtilizationOptions[3];
  }
};
