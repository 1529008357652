import { PageContainer } from "@flixbus/honeycomb-react";
import * as React from "react";
import * as css from "./Layout.scss";

type LayoutProps = {
  children: React.ReactNode;
};

const Layout: React.FC<LayoutProps> = ({ children }) => (
  <PageContainer data-testid="layout" extraClasses={css.defaultContainer}>
    {children}
  </PageContainer>
);

export default Layout;
