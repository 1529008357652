@import "variables";

.defaultContainer {
  height: 100%;
  padding-top: calc(60px + #{$spacing-2});
  @include on-bp(md) {
    padding-top: calc(108px + #{$spacing-2});
  }
  @include on-bp(xl) {
    padding-top: calc(60px + #{$spacing-2});
  }
}
