import { Switch } from "@flixbus/honeycomb-react";
import { autocompleteStyles } from "@flixbus-phx/marketplace-common";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import ReactSelect from "react-select";
import {
  PreferenceInput,
  RidesFullyBookedPreference,
} from "../../../shared/types/schema";
import {
  getRideUtilization,
  rideUtilizationOptions,
} from "../../helper/rideUtilization/rideUtilization";
import SelectOption from "../../types";
import * as css from "./RideFullyBookedItem.scss";

type Props = {
  preference: RidesFullyBookedPreference;
  onChange: (values: PreferenceInput["ridesFullyBooked"]) => void;
};

const RideFullyBookedItem: React.FC<Props> = ({ preference, onChange }) => {
  return (
    <div className={css.listItem}>
      <div>
        <p>
          <FormattedMessage id="settings.fullyBookedRidesNotification.header" />
        </p>
        <p className={css.info}>
          <FormattedMessage id="settings.fullyBookedRidesNotification.subheader" />
        </p>
        <div data-testid="select-wrapper" className={css.selectWrapper}>
          <ReactSelect
            id="fully-booked-rides-select"
            data-testid="fully-booked-rides-select"
            defaultValue={getRideUtilization(preference.utilization)}
            options={rideUtilizationOptions}
            styles={autocompleteStyles(false, true)}
            onChange={(selectedValue) => {
              onChange({
                active: preference.active,
                utilization: (selectedValue as SelectOption).value,
              });
            }}
          />
        </div>
      </div>
      <div className={css.switchWrapper}>
        <Switch
          id="fully-booked-rides-switch"
          aria-label="fully-booked-rides"
          defaultChecked={preference.active}
          onChange={({ currentTarget }) => {
            onChange({
              active: currentTarget.checked,
              utilization: preference.utilization,
            });
          }}
          extraClasses={css.switch}
        />
      </div>
    </div>
  );
};

export default RideFullyBookedItem;
