import { Heading, ListWrapper, ListWrapperItem, Spinner } from "@flixbus/honeycomb-react";
import {
  addNotification,
  ErrorTypes,
  formatErrorMessage,
  legacyTranslate,
  NotificationType,
  ErrorPage,
} from "@flixbus-phx/marketplace-common";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { PreferenceInput, User } from "../shared/types/schema";
import * as css from "./Settings.scss";
import {
  useGetUserQuery,
  useUpdateUserPreferenceMutation,
} from "./api/operations.generated";
import RideFullyBookedItem from "./ui/rideFullyBookedItem/RideFullyBookedItem";
import ScheduleExpiryItem from "./ui/scheduleExpiryItem/ScheduleExpiryItem";
import SettingsHeader from "./ui/settingsHeader/SettingsHeader";

const Settings: React.FC = () => {
  const intl = useIntl();

  const { data: userData, loading, error } = useGetUserQuery();

  const [updateUserPreference] = useUpdateUserPreferenceMutation({
    onError: (err) =>
      addNotification({
        message: `User settings update failed: ${formatErrorMessage(err.message)}`,
        type: NotificationType.Danger,
      }),
    onCompleted: () => {
      addNotification({
        message: intl.formatMessage({ id: "settings.update.success" }),
      });
    },
  });

  const handleUpdatePreference = (id: User["id"], values: PreferenceInput) => {
    updateUserPreference({
      variables: {
        preference: {
          ...values,
        },
      },
      optimisticResponse: {
        updateUserPreference: {
          id,
          preference: {
            ridesFullyBooked: {
              ...values.ridesFullyBooked,
              __typename: "RidesFullyBookedPreference",
            },
            scheduleExpiryReminder: {
              ...values.scheduleExpiryReminder,
              __typename: "ScheduleExpiryReminderPreference",
            },
            __typename: "Preference",
          },
          __typename: "User",
        },
      },
    });
  };

  if (loading) {
    return (
      <div className={css.spinnerWrapper}>
        <Spinner data-testid="spinner" />
      </div>
    );
  }

  if (userData?.getUser) {
    const { id, displayName, mail, preference } = userData.getUser;

    return (
      <>
        <SettingsHeader name={displayName} mail={mail || ""} />

        <div data-testid="notification-section">
          <Heading data-testid="notification-header-wrapper" size={3}>
            <FormattedMessage id="settings.notificationSection.header" />
          </Heading>

          <ListWrapper>
            <ListWrapperItem
              data-testid="fully-booked-rides-item"
              extraClasses={css.listWrapperItem}
            >
              <RideFullyBookedItem
                preference={preference.ridesFullyBooked}
                onChange={(values) =>
                  handleUpdatePreference(id, {
                    ridesFullyBooked: values,
                    scheduleExpiryReminder: {
                      active: preference.scheduleExpiryReminder.active,
                    },
                  })
                }
              />
            </ListWrapperItem>
            <ListWrapperItem>
              <ScheduleExpiryItem
                preference={preference.scheduleExpiryReminder}
                onChange={(values) =>
                  handleUpdatePreference(id, {
                    ridesFullyBooked: {
                      active: preference.ridesFullyBooked.active,
                      utilization: preference.ridesFullyBooked.utilization,
                    },
                    scheduleExpiryReminder: values,
                  })
                }
              />
            </ListWrapperItem>
          </ListWrapper>
        </div>
      </>
    );
  }

  if (error) {
    const errorType = error.graphQLErrors?.some(
      (graphqlError) => graphqlError.extensions?.classification === "NOT_FOUND"
    )
      ? ErrorTypes.e403
      : ErrorTypes.e500;

    return <ErrorPage type={errorType} translate={legacyTranslate} />;
  }

  return <></>;
};

export default Settings;
